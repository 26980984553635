export class style {
}

export class style {
}

.micontenedot {
    padding-left: 50px;
    padding-right: 50px;
}

.alinearTexto-card {
    text-align: inherit;
    padding-top: 15%;
}

.centrarimg {
    content: "";
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 40vh;
}

.centrartexto {
    text-align: center;
}

.responsive {
    max-width: 100%;
    height: auto;
}

.responsive-full {
    width: 100%;
    height: auto;

    margin-top: 40px;
}

.image-capa {
    background-color: rgba(0,0,0,0.8);
    filter:brightness(0.4);
}

.titulo {
    text-align: center;
}

.text-color {
    color: #ffffff !important;
}

.text-color-suave {
    color: rgba(255, 255, 255, 0.58) !important;
}

.hover:hover {
    color: #fd5a69 !important;
}

.animacion {
    position: absolute;
}

.pantalla-completa{
    width:100vw;
    height:100vh;
}

.contenedor {
    align-items: center;
    display: flex;
    justify-content: center;
}

